import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Message, Icon } from 'semantic-ui-react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { consulta, respuestaWEB, registra } from './funciones.js';

const RegistroComidas = () => {
    const [registros, setRegistros] = useState([]);
    const [modalAbierto, setModalAbierto] = useState(false);
    const [error, setError] = useState(null);
    const [cargando, setCargando] = useState(false);
    const [nuevoRegistro, setNuevoRegistro] = useState({
        dia: '',
        peso: '',
        peso_comida: '',
        comida: '',
        bebida: ''
    });

    //******  COMUNICACION */
    //FUNCIONES de comunicación con el servidor

    let intervalo = null;
    useEffect(() => {
        registra();
        cargarRegistrosDesdeCSV();
        console.log("ChatRoom component mounted");
    }, []);

    const funcionOKResp = (res) => {
        clearInterval(intervalo);
        if (!('data' in res)) {
            console.log(res);
            return;
        }
        setRegistros(res.data);
        setCargando(false);
        console.log(res);
    };

    const funcionErrorResp = (error) => {
        clearInterval(intervalo);
        setCargando(false);
        console.log(error);
    };

    const consultaRespuesta = async (id) => {
        const data = 'hola';
        let res = await respuestaWEB(data, id, funcionOKResp, funcionErrorResp);
        console.log(res);
    };

    const funcionOK = (res) => {
        console.log(res);
    };

    const funcionError = (error) => {
        console.log(error);
    };

    const enviaConsulta = async (tipo, datos) => {
        try {
            const data = datos;
            const uniqueId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            const funcion = tipo;
            let res = await consulta(funcion, JSON.stringify(data), uniqueId, funcionOK, funcionError);
            intervalo = setInterval(() => { consultaRespuesta(uniqueId); }, 500);
            console.log(res);
        } catch (error) {
            console.error('Error al obtener la posición:', error);
        }
    };

    //******  FIN COMUNICACION */

    const cargarRegistrosDesdeCSV = async () => {
        setCargando(true);
        setError(null);
        try {
            enviaConsulta('load', {});
        } catch (err) {
            setError('Error al cargar los registros');
            console.error('Error al cargar los registros:', err);
        }
    };

    const handleAbrirModal = () => {
        const ahora = new Date();
        setNuevoRegistro({
            ...nuevoRegistro,
            dia: format(ahora, 'yyyy-MM-dd HH:mm', { locale: es })
        });
        setModalAbierto(true);
    };

    const handleCerrarModal = () => setModalAbierto(false);

    const handleCambioInput = (e, { name, value }) => {
        setNuevoRegistro({ ...nuevoRegistro, [name]: value });
    };

    const handleGuardarRegistro = async () => {
        setCargando(true);
        setError(null);
        try {
            await enviaConsulta('save', nuevoRegistro);
            await cargarRegistrosDesdeCSV();
            handleCerrarModal();
            setNuevoRegistro({
                dia: '',
                peso: '',
                peso_comida: '',
                comida: '',
                bebida: ''
            });
        } catch (err) {
            setError('Error al guardar el registro');
            console.error('Error al guardar el registro:', err);
        }
        setCargando(false);
    };

    const handleEliminarRegistro = async (index) => {
        setCargando(true);
        setError(null);
        try {
            const registroAEliminar = registros[index];
            await enviaConsulta('delete', registroAEliminar);
            await cargarRegistrosDesdeCSV();
        } catch (err) {
            setError('Error al eliminar el registro');
            console.error('Error al eliminar el registro:', err);
        }
        setCargando(false);
    };

    if (cargando) return <div>Cargando...</div>;

    return (
        <div>
            {error && <Message negative>{error}</Message>}
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Día</Table.HeaderCell>
                        <Table.HeaderCell>Peso</Table.HeaderCell>
                        <Table.HeaderCell>Peso Comida</Table.HeaderCell>
                        <Table.HeaderCell>Comida</Table.HeaderCell>
                        <Table.HeaderCell>Bebida</Table.HeaderCell>
                        <Table.HeaderCell>Acciones</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {registros.map((registro, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{registro.dia}</Table.Cell>
                            <Table.Cell>{registro.peso}</Table.Cell>
                            <Table.Cell>{registro.peso_comida}</Table.Cell>
                            <Table.Cell>{registro.comida}</Table.Cell>
                            <Table.Cell>{registro.bebida}</Table.Cell>
                            <Table.Cell>
                                <Button icon color="red" onClick={() => handleEliminarRegistro(index)}>
                                    <Icon name="trash" />
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>

            <Button primary onClick={handleAbrirModal}>Añadir Registro</Button>

            <Modal open={modalAbierto} onClose={handleCerrarModal}>
                <Modal.Header>Añadir Nuevo Registro</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Día</label>
                            <Input name="dia" value={nuevoRegistro.dia} readOnly />
                        </Form.Field>
                        <Form.Field>
                            <label>Peso Kg</label>
                            <Input name="peso" value={nuevoRegistro.peso} onChange={handleCambioInput} />
                        </Form.Field>
                        <Form.Field>
                            <label>Peso Comida gr</label>
                            <Input name="peso_comida" value={nuevoRegistro.peso_comida} onChange={handleCambioInput} />
                        </Form.Field>
                        <Form.Field>
                            <label>Comida</label>
                            <Input name="comida" value={nuevoRegistro.comida} onChange={handleCambioInput} />
                        </Form.Field>
                        <Form.Field>
                            <label>Bebida</label>
                            <Input name="bebida" value={nuevoRegistro.bebida} onChange={handleCambioInput} />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleCerrarModal}>Cancelar</Button>
                    <Button positive onClick={handleGuardarRegistro}>Guardar</Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default RegistroComidas;
