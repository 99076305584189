export function url_base(){
    let url = window.location.href;
    const ultimoSlashIndex = url.lastIndexOf('/');
    if (ultimoSlashIndex>6) url = url.substring(0, ultimoSlashIndex);
    if(url.includes(':3000') || url.includes(':5000')){
        url = url.replace(/:\d+/, '');
        url+=':3000/';
    }else{
      url+='/';
    }

    url="http://localhost:5000/"
    url="https://prueba.ratherbe.es/PHP/"

    return url;
}

//Realiza la consulta al servidor python
export async function consulta(funcion,data,id,funcionOK,funcionError){
    //Compone la cadena de búsqueda
    let url = url_base();
    await fetch(url + `API/consulta`, { 
        method: 'POST',
        body: JSON.stringify({
            accion: funcion,
            parametro: data,
            id: id,
        }),
        headers: {
            'AutorizaCana': 'Bearer ' + localStorage.getItem('token'),
        },
        })           
    .then(response => response.json())
    .then(res => {
        if (typeof res === 'object' && res !== null && Object.keys(res).length > 0){
            //Publica el listado de recetas
            if ('error' in res){
                //ERROR, no lo ha podido procesar
                funcionError(res.error);
            }else if ('estado' in res){
                //Procesa la información
                console.log(res);
            }else{
                //Procesa la información
                funcionOK(res);
            }            
        }else{
            //ERROR, no lo ha podido procesar
            funcionError('No se ha podido procesar la respuesta del servidor');
        }  
    });
}

//Realiza la consulta al servidor python
export async function respuestaWEB(data,id,funcionOK,funcionError){
  //Compone la cadena de búsqueda
  let url = url_base();
  try {
    await fetch(url + `API/respuestaWeb`, { 
        method: 'POST',
        body: JSON.stringify({
            parametro: data,
            id: id,
        }),
        headers: {
            'AutorizaCana': 'Bearer ' + localStorage.getItem('token'),
        },
        })           
    .then(response => response.json())
    .then(res => {
        if (typeof res === 'object' && res !== null && Object.keys(res).length > 0){
            //Publica el listado de recetas
            if ('error' in res){
                //ERROR, no lo ha podido procesar
                funcionError(res.error);
            }else if ('estado' in res){
                //Procesa la información
                console.log(res);
            }else{
                //Procesa la información
                funcionOK(res);
            }            
        }else{
            //ERROR, no lo ha podido procesar
            funcionError('No se ha podido procesar la respuesta del servidor');
        }  
    })
    } catch (error) {
      console.log(error.message+"\n DATA:  "+data);
    }
}
  

export async function registra() {
    try {
      let url = url_base();
      await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          user: 'pepitoPalotes99!',
        }),
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          if (data.status) {
            localStorage.setItem('token', data.status)
          } else {
            //set error
          }
        })
    } catch (error) {
      console.log(error.message)
    }
  }